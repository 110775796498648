const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "opensearch_metrics" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<opensearch_pod_label_name>=<opensearch_pod_label_value>"
            }
        }

        discovery.relabel "opensearch_metrics" {
            targets = discovery.kubernetes.opensearch_metrics.targets
        
            rule {
                source_labels = ["__address__"]
                regex         = "([^:]+)(:[0-9]+)?"
                target_label  = "instance"
                replacement   = "\${1}"
            }
        }
        
        prometheus.scrape "opensearch_metrics" {
            targets      = discovery.relabel.opensearch_metrics.output
            metrics_path = "/monitoring/prometheus/metrics"
            job_name     = "integrations/opensearch"
            forward_to   = [prometheus.relabel.integrations_opensearch_cluster_label.receiver]
        }
        
        prometheus.relabel "integrations_opensearch_cluster_label" {
            rule {
                action      = "labelmap"
                regex       = "cluster"
                replacement = "opensearch_cluster"
            }
        
            rule {
                action = "labeldrop"
                regex  = "cluster"
            }

            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = "(<opensearch_namespace>:opensearch.*)"
            replacement   = "opensearch"
            target_label  = "integration"
        }

        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_ip"]
            separator = ":"
            regex = "opensearch:(.*)"
            target_label = "instance"
        }

        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_node_name"]
            separator = ":"
            regex = "opensearch:(.*)"
            target_label = "node"
        }

        rule {
            source_labels = ["integration"]
            regex = "opensearch"
            replacement = "integrations/opensearch"
            target_label = "job"
        }

        rule {
            source_labels = ["integration"]
            regex = "opensearch"
            replacement = "<your-opensearch-cluster-name>"
            target_label = "opensearch_cluster"
        }`;

export default { metrics, logs };

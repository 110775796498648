const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "couchbase" {
            role = "endpoints"
            selectors {
                role = "service"
                field = "metadata.name=<couchbase_service_name>"
            }
        }

        discovery.relabel "couchbase" {
            targets = discovery.kubernetes.couchbase.targets
        
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
                separator = "-"
                target_label = "instance"
            }
            rule {
                replacement = "<your-couchbase-cluster-name>"
                target_label = "couchbase_cluster"
            }
        }
        
        prometheus.scrape "metrics_couchbase" {        
            targets      = discovery.relabel.couchbase.output
            job_name     = "integrations/couchbase"
            honor_labels = true
            basic_auth {
                username = "<your-couchbase-username>"
                password = "<your-couchbase-password>"
            }
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_name","__meta_kubernetes_pod_container_name"]
            separator     = ":"
            regex         = "<couchbase_pod_name>:<couchbase-log-forwarder>"
            replacement   = "couchbase"
            target_label  = "integration"
        }
        
    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"couchbase\\"}"
        
            stage.multiline {
                firstline     = \`\\[\d\\] couchbase.log.*\`
                max_lines     = 0
                max_wait_time = "3s"
            }

            stage.drop {
                expression = "---"
            }

            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .pod }}"
            }

            stage.static_labels {
                values = {
                    couchbase_cluster = "<your-couchbase-cluster-name>",
                    job               = "integrations/couchbase",
                }
            }
        
            stage.labels {
                values = {
                    instance = null,
                    level = null,
                }
            }
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "metrics_clickhouse" {
            role = "endpoints"
            selectors {
                role = "endpoints"
                label = "<endpoints label>=<endpoints label value>"
            }
        }
        
        discovery.relabel "metrics_clickhouse" {
            targets = discovery.kubernetes.metrics_clickhouse.targets
        
            rule {
                source_labels = ["__meta_kubernetes_endpoint_port_name"]
                regex = "<clickhouse service listening port>"
                action = "keep"
            }
            rule {
                source_labels = ["__meta_kubernetes_pod_label_clickhouse_cluster"]
                target_label = "clickhouse_cluster"
            }
            rule {
                source_labels = ["__meta_kubernetes_pod_name"]
                target_label = "instance"
            }
        }
        
        prometheus.scrape "metrics_clickhouse" {
            targets      = discovery.relabel.metrics_clickhouse.output
            job_name     = "integrations/clickhouse"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_label_<your_clickhouse_pod_label_name>"]
            separator     = ":"
            regex         = "<your_clickhouse_namespace>:<your_clickhouse_pod_label_value>"
            replacement   = "clickhouse"
            target_label  = "integration"
        }
        
    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"clickhouse\\"}"
            
            stage.multiline {
                firstline     = \`^([\\d]{4}).([\\d]{1,2}).([\\d]{1,2}) (([\\d]+):([\\d]+):([\\d]+).([\\d]+))\`
                max_lines     = 0
                max_wait_time = "0s"
            }
        
            stage.regex {
                expression = \`(?P<timestamp>([\\d]{4}).([\\d]{1,2}).([\\d]{1,2}) (([\\d]+):([\\d]+):([\\d]+).([\\d]+))) \\[ \\d+ \\] \\{.*\\} <(?P<level>.+)> (?P<message>(?s:.*))$\`
            }

            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .container }}"
            }

            stage.labels {
                values = {
                    instance = "pod",
                    level = "",
                }
            }
        
            stage.static_labels {
                values = {
                    job = "integrations/clickhouse",
                    clickhouse_cluster = "<your_clickhouse_cluster_name>",
                }
            }
        }`;

export default { metrics, logs };

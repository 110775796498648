const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "presto" {
            role = "service"
            selectors {
                role  = "service"
                label = "<service label>=<service label value>"
            }
        }
        
        discovery.relabel "presto" {
            targets = discovery.kubernetes.presto.targets
            rule {
                source_labels = ["__meta_kubernetes_service_port_number"]
                regex = "<your-presto-service-listening-port>"
                action = "keep"
            }
        }

        prometheus.scrape "presto" {
            targets      = discovery.relabel.presto.output
            job_name     = "integrations/presto"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

export default { metrics };

import aerospike from './aerospike';
import alloy from './alloy';
import apacheActivemq from './apacheActivemq';
import apacheAirflow from './apacheAirflow';
import apacheCassandra from './apacheCassandra';
import apacheCouchDB from './apacheCouchDB';
import apacheHadoop from './apacheHadoop';
import apacheHttp from './apacheHttp';
import apacheMesos from './apacheMesos';
import apacheSolr from './apacheSolr';
import apacheTomcat from './apacheTomcat';
import certManager from './certManager';
import ciliumEnterprise from './ciliumEnterprise';
import clickhouse from './clickhouse';
import cockroachdb from './cockroachdb';
import coredns from './coredns';
import couchbase from './couchbase';
import etcd from './etcd';
import gitlab from './gitlab';
import ibmDb2 from './ibm-db2';
import ibmMq from './ibm-mq';
import istio from './istio';
import kafka from './kafka';
import linuxNode from './linuxNode';
import mimir from './mimir';
import mongodb from './mongodb';
import mssql from './mssql';
import mysql from './mysql';
import nginx from './nginx';
import openldap from './openldap';
import opensearch from './opensearch';
import oracledb from './oracledb';
import pgbouncer from './pgbouncer';
import postgres from './postgres';
import presto from './presto';
import squid from './squid';
import tensorflow from './tensorflow';
import varnish from './varnish';
import velero from './velero';
import wildfly from './wildfly';

interface Integration {
  metrics?: string;
  logs?: string;
  extraLogsInstructions?: string;
}
interface IntegrationList {
  [key: string]: Integration;
}

const integrations: IntegrationList = {
  aerospike,
  'alloy-health': alloy,
  'apache-activemq': apacheActivemq,
  'apache-airflow': apacheAirflow,
  'apache-cassandra': apacheCassandra,
  'apache-couchdb': apacheCouchDB,
  'apache-hadoop': apacheHadoop,
  'apache-http': apacheHttp,
  'apache-mesos': apacheMesos,
  'apache-solr': apacheSolr,
  'apache-tomcat': apacheTomcat,
  'cert-manager': certManager,
  'cilium-enterprise': ciliumEnterprise,
  clickhouse,
  cockroachdb,
  coredns,
  couchbase,
  etcd,
  gitlab,
  kafka,
  'ibm-db2': ibmDb2,
  'ibm-mq': ibmMq,
  istio,
  'linux-node': linuxNode,
  mimir,
  mongodb,
  mssql,
  mysql,
  nginx,
  openldap,
  opensearch,
  oracledb,
  pgbouncer,
  postgres,
  presto,
  squid,
  tensorflow,
  'varnish-cache': varnish,
  velero,
  wildfly,
};

export default integrations;

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        prometheus.exporter.postgres "postgres" {
            data_source_names = ["postgresql://<username>:<password>@<your_postgres_instance_name>:5432/postgres"]
        }
        
        prometheus.scrape "postgres" {
          targets      = prometheus.exporter.postgres.postgres.targets
          job_name     = "integrations/postgres_exporter"
          forward_to   = [prometheus.relabel.postgres.receiver]
        }
        
        prometheus.relabel "postgres" {
            rule {
                replacement = "<your_postgres_instance_name>"
                target_label = "instance"
            }
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_label_<your_postgres_selector_labelname>"]
            separator     = ":"
            regex         = "<your_postgres_namespace>:<your_postgres_selector_labelvalue>"
            replacement   = "postgres"
            target_label  = "integration"
        }

        rule {
            source_labels = ["integration"]
            regex = "postgres"
            replacement = "<your_postgres_instance_name>"
            target_label = "instance"
        }

        rule {
            source_labels = ["integration"]
            regex = "postgres"
            replacement = "integrations/postgres_exporter"
            target_label = "job"
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "aerospike" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<aerospike_pod_label>=<aerospike_pod_label_value>"
            }
        }
        
        discovery.relabel "aerospike" {
            targets = discovery.kubernetes.aerospike.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<aerospike_pod_port_number>"
                action = "keep"
            }
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
                separator = "-"
                target_label = "instance"
            }
            rule {
                replacement = "<your-aerospike-cluster-name>"
                target_label = "aerospike_cluster"
            }
            rule {
                replacement = "integrations/aerospike"
                target_label = "job"
            }
        }
        
        prometheus.scrape "metrics_aerospike" {
            targets      = discovery.relabel.aerospike.output
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_<aerospike_pod_label>"]
            regex = "<aerospike_pod_label_value>"
            replacement = "aerospike"
            target_label = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"aerospike\\"}"

            stage.multiline {
                firstline     = "\\\\w{3} \\\\d{2} \\\\d{4}"
                max_lines     = 0
                max_wait_time = "3s"
            }
        
            stage.regex {
                expression = "\\\\w{3} \\\\d{2} \\\\d{4} \\\\d{2}:\\\\d{2}:\\\\d{2} \\\\w{3}: (?P<level>\\\\w+) \\\\((?P<context>\\\\w+)\\\\): (?P<trace>\\\\(\\\\S+\\\\))\\\\s+(?P<message>(?s:.*))$"
            }
            
            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .container }}"
            }

            stage.static_labels {
                values = {
                    aerospike_cluster = "<your-aerospike-cluster-name>",
                    job               = "integrations/aerospike",
                }
            }
        
            stage.labels {
                values = {
                    context = null,
                    instance = null,
                    level   = null,
                }
            }
        }`;

export default { metrics, logs };

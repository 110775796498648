const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "apache_cassandra" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<apache_cassandra_pod_label>=<apache_cassandra_pod_label_value>"
            }
        }
        
        discovery.relabel "apache_cassandra" {
            targets = discovery.kubernetes.apache_cassandra.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<apache_cassandra_jmx_port_number>"
                action = "keep"
            }
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
                separator = "-"
                target_label = "instance"
            }
            rule {
                replacement = "<your-cassandra-cluster-name>"
                target_label = "cassandra_cluster"
            }
            rule {
                replacement = "integrations/apache-cassandra"
                target_label = "job"
            }
        }
        
        prometheus.scrape "metrics_apache_cassandra" {
            targets      = discovery.relabel.apache_cassandra.output
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_<apache_cassandra_pod_label>"]
            regex = "<apache_cassandra_pod_label_value>"
            replacement = "apache_cassandra"
            target_label = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"apache_cassandra\\"}"
            
            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .container }}"
            }

            stage.static_labels {
                values = {
                    cassandra_cluster = "<your-cassandra-cluster-name>",
                    job               = "integrations/apache-cassandra",
                }
            }

            stage.labels {
                values = {
                    instance = null,
                }
            }
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        prometheus.exporter.mssql "integrations_mssql" {
            connection_string = "sqlserver://<db_user>:<db_password>@<mssql_service>.<mssql_namespace>.svc.cluster.local:1433"
        }
        
        prometheus.scrape "integrations_mssql" {
            targets    = prometheus.exporter.mssql.integrations_mssql.targets
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
            job_name   = "integrations/mssql"
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = "(<mssql_namespace>:<mssql_pod_prefix>.*)"
            replacement   = "mssql"
            target_label  = "integration"
        }
        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_node_name"]
            separator     = ":"
            regex         = "mssql:(.*)"
            target_label  = "host"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"mssql\\"}"

            stage.multiline {
                firstline = "^\\\\s*\\\\d{4}-\\\\d{2}-\\\\d{2}\\\\s+\\\\d{2}:\\\\d{2}:\\\\d{2}\\\\.\\\\d+"
            }
            stage.regex {
                expression = "^(?s)(?P<timestamp>\\\\s*\\\\d{4}-\\\\d{2}-\\\\d{2}\\\\s+\\\\d{2}:\\\\d{2}:\\\\d{2}\\\\.\\\\d+)\\\\s+(?P<component>\\\\w+)\\\\s+(?P<message>.*)$"
            }
            stage.timestamp {
                format = "RFC3339Nano"
                source = "timestamp"
            }
            stage.static_labels {
                values = {
                    log_type = "mssql_error",
                    job = "integrations/mssql",
                    instance = "<mssql_service>.<mssql_namespace>.svc.cluster.local:1433",
                }
            }
            stage.labels {
                values = {
                    message = "message",
                    component = "component",
                }
            }
        }`;

export default { metrics, logs };

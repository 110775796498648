const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "integrations_wildfly" {
            role = "endpoints"
            selectors {
                role = "service"
                field = "metadata.name=wildfly"
            }
        }
        
        prometheus.scrape "integrations_wildfly" {
            targets  = discovery.kubernetes.integrations_wildfly.targets
            job_name = "integrations/wildfly"
            basic_auth {
                username = "<wildfly_user>"
                password = "<wildfly_password>"
            }
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = "(<wildfly_namespace>:wildfly.*)"
            replacement   = "wildfly"
            target_label  = "integration"
        }

        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_ip","__meta_kubernetes_pod_container_port_number"]
            separator = ":"
            regex = "wildfly:(.*:.*)"
            target_label = "instance"
        }
        
        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_node_name"]
            separator = ":"
            regex = "wildfly:(.*)"
            target_label = "host"
        }
        
        rule {
            source_labels = ["integration"]
            regex = "wildfly"
            replacement = "integrations/wildfly"
            target_label = "job"
        }
        
        rule {
            source_labels = ["integration"]
            regex = "wildfly"
            replacement = "wildfly"
            target_label = "log_type"
        }
        
    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"wildfly\\"}"

            stage.multiline {
                firstline = "\\\\\\\\d+:\\\\\\\\d+:\\\\\\\\d+,\\\\\\\\d+"
                max_lines     = 0
                max_wait_time = "3s"
            }
        }`;

export default { metrics, logs };

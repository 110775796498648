const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "varnish" {
            role = "service"
            selectors {
                role = "service"
                field = "metadata.name=<varnish-cache-service>"
            }
            namespaces {
                names = ["<varnish-cache-namespace>"]
            }
        }
        
        discovery.relabel "varnish" {
            targets = discovery.kubernetes.varnish.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<varnish-cache-exporter-port>"
                action = "keep"
            }
        }

        prometheus.scrape "varnish" {
            targets      = discovery.relabel.varnish.output
            job_name     = "integrations/varnish-cache"
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const extraLogsInstructions = `
<p>
To collect logs from Varnish Cache, <code>varnishncsa</code> and Alloy sidecars must be added to the deployment configuration. 
Additional ConfigMaps for custom Alloy configurations must be created. The deployment will need an additional volume for logs and all sidecars will need <code>volumeMounts</code> for that volume.
</p>
<p>
For additional guidance, refer to <a href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-varnish-cache/#kubernetes-log-collection">this documentation</a>
</p>
`;

export default { metrics, extraLogsInstructions };

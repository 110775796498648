const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "activemq" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<activemq_pod_label>=<activemq_pod_label_value>"
            }
        }

        discovery.relabel "activemq" {
            targets = discovery.kubernetes.activemq.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<activemq_jmx_port_number>"
                action = "keep"
            }
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
                separator = "-"
                target_label = "instance"
            }
            rule {
                replacement = "<your-activemq-cluster-name>"
                target_label = "activemq_cluster"
            }
            rule {
                replacement = "integrations/apache-activemq"
                target_label = "job"
            }
        }

        prometheus.scrape "activemq" {
            targets      = discovery.relabel.activemq.output
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_<activemq_pod_label>"]
            regex         = "<activemq_pod_label_value>"
            replacement   = "activemq"
            target_label  = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"activemq\\"}"
  
              stage.multiline {
                  firstline     = "(TRACE|DEBUG|INFO|WARN|ERROR|FATAL)"
                  max_lines     = 0
                  max_wait_time = "3s"
              }
          
              stage.regex {
                  expression = "(?P<level>TRACE|DEBUG|INFO|WARN|ERROR|FATAL)\\\\s*[\\\\|:]\\\\s*(?P<message>.+)"
              }
  
              stage.template {
                  source   = "instance"
                  template = "{{ .namespace }}-{{ .container }}"
              }
  
              stage.static_labels {
                  values = {
                      activemq_cluster = "<your-activemq-cluster-name>",
                      job              = "integrations/apache-activemq",
                  }
              }
          
              stage.labels {
                  values = {
                      instance = null,
                      level = null,
                  }
              }
          }`;

export default { metrics, logs };

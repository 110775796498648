const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_<nginx_label>"]
            regex         = "<nginx_label_value>"
            replacement   = "nginx"
            target_label  = "integration"
        }

        rule {
            source_labels = ["integration", "__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
            separator = "-"
            regex = "nginx-(.*-.*)"
            target_label = "instance"
        }

        rule {
            source_labels = ["integration"]
            regex = "nginx"
            replacement = "integrations/nginx"
            target_label = "job"
        }`;

export default { logs };

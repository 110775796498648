const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
integrations:
  etcd:
    instances:
      - name: <your_etcd_instance_name>
        labelSelectors:
          <etcd-pod-label>: <etcd-pod-label-value>
`;

export default { metrics };

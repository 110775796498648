const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "ibm_mq" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<ibm-mq-pod-label>=<ibm-mq-pod-label-value>"
            }
        }
        
        discovery.relabel "ibm_mq" {
            targets = discovery.kubernetes.ibm_mq.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "9157"
                action = "keep"
            }
            rule {
                target_label = "mq_cluster"
                replacement = "<ibm-mq-cluster-label>"
            }
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
                separator = "-"
                target_label = "instance"
            }
        }
        prometheus.scrape "ibm_mq" {
            targets    = discovery.relabel.ibm_mq.output
            job_name   = "integrations/ibm-mq"
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_<ibm-mq-pod-label>"]
            regex = "<ibm-mq-pod-label-value>"
            replacement = "ibm-mq"
            target_label = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"ibm-mq\\"}"

            stage.multiline {
                firstline = \`^\\s*\\d{2}\\/\\d{2}\\/\\d{2}\\s+\\d{2}:\\d{2}:\\d{2}\\s*-'\`
            }

            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .container }}"
            }

            stage.static_labels {
                values = {
                    mq_cluster = "<your-cluster-name>",
                    log_type   = "mq-qmgr-error",
                    job        = "integrations/ibm-mq",
                }
            }
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "apache_couchdb" {
            role = "endpoints"
            selectors {
                role = "endpoints"
                label = "<apache_couchdb_pod_label>=<apache_couchdb_pod_label_value>"
            }
        }
        
        discovery.relabel "apache_couchdb" {
            targets = discovery.kubernetes.apache_couchdb.targets
        
            rule {
                source_labels = ["__meta_kubernetes_service_name","__meta_kubernetes_endpoint_port_name"]
                separator     = ":"
                regex         = ".*-couchdb:metrics"
                action        = "keep"
            }
        
            rule {
                replacement  = "<your-couchdb-cluster-name>"
                target_label = "couchdb_cluster"
            }
        }
        
        prometheus.scrape "apache_couchdb" {
            targets      = discovery.relabel.apache_couchdb.output
            metrics_path = "/_node/_local/_prometheus"
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
            job_name     = "integrations/couchdb"
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace","__meta_kubernetes_pod_name","__meta_kubernetes_pod_container_port_name"]
            separator     = ":"
            regex         = "sample-apps:.*-couchdb.*:metrics"
            replacement   = "couchdb"
            target_label  = "integration"
        }
        
        rule {
            source_labels = ["integration","__meta_kubernetes_pod_ip","__meta_kubernetes_pod_container_port_number"]
            separator   = ":"
            regex        = "couchdb:(.*):(.*)"
            replacement  = "$1:$2"
            target_label = "instance"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"couchdb\\"}"

            stage.static_labels {
                values = {
                    couchdb_cluster = "<your-couchdb-cluster-name>",
                    job             = "integrations/apache-cassandra",
                    log_type        = "couchdb",
                }
            }

            stage.labels {
                values = {
                    instance = null,
                }
            }
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "tensorflow_metrics" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<tensorflow_pod_label_name>=<tensorflow_pod_label_value>"
            }
        }
        
        discovery.relabel "tensorflow_metrics" {
            targets = discovery.kubernetes.tensorflow_metrics.targets
            rule {
                source_labels = ["__address__"]
                regex         = "([^:]+)(:[0-9]+)?"
                target_label  = "instance"
                replacement   = "\${1}"
            }
        }

        prometheus.scrape "tensorflow_metrics" {
            targets      = discovery.relabel.tensorflow_metrics.output
            metrics_path = "/monitoring/prometheus/metrics"
            job_name     = "integrations/tensorflow"
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = "(<tensorflow_namespace>:tensorflow.*)"
            replacement   = "tensorflow"
            target_label  = "integration"
        }

        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_ip"]
            separator = ":"
            regex = "tensorflow:(.*)"
            target_label = "instance"
        }
        
        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_node_name"]
            separator = ":"
            regex = "tensorflow:(.*)"
            target_label = "__host__"
        }

        rule {
            source_labels = ["integration"]
            regex = "tensorflow"
            replacement = "integrations/tensorflow"
            target_label = "job"
        }
        
        rule {
            source_labels = ["integration"]
            regex = "tensorflow"
            replacement = "tensorflow"
            target_label = "name"
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
integrations:
  mimir:
    instances:
      - name: <your_mimir_instance_name>
        labelSelectors:
          app.kubernetes.io/name: <your_mimir_instance_name>
        metrics:
          enabled: true`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
integrations:
  mimir:
    instances:
      - name: <your_mimir_instance_name>
        labelSelectors:
          app.kubernetes.io/name: <your_mimir_instance_name>
        logs:
          enabled: true`;

export default { metrics, logs };

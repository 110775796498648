const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        // Cilium Agent
        discovery.kubernetes "cilium_agent" {
            role = "service"
            selectors {
                role = "service"
                label = "k8s-app=cilium"
            }
        }
        
        discovery.relabel "cilium_agent" {
            targets = discovery.kubernetes.cilium_agent.targets
            rule {
                source_labels = ["__meta_kubernetes_endpoint_port_name"]
                regex = "metrics"
                action = "keep"
            }
            rule {
                source_labels = ["__meta_kubernetes_service_label_k8s_app"]
                target_label = "k8s_app"
            }
        }

        prometheus.scrape "cilium_agent" {
            targets      = discovery.relabel.cilium_agent.output
            job_name     = "integrations/cilium-enterprise/cilium-agent"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        // Cilium Operator
        discovery.kubernetes "cilium_operator" {
            role = "service"
            selectors {
                role = "service"
                label = "name=cilium-operator,io.cilium/app=operator"
            }
        }

        discovery.relabel "cilium_operator" {
            targets = discovery.kubernetes.cilium_operator.targets
            rule {
                source_labels = ["__meta_kubernetes_endpoint_port_name"]
                regex = "metrics"
                action = "keep"
            }
            rule {
                source_labels = ["__meta_kubernetes_service_label_io_cilium_app_app"]
                target_label = "io_cilium_app"
            }
        }

        prometheus.scrape "cilium_operator" {
            targets      = discovery.relabel.cilium_operator.output
            job_name     = "integrations/cilium-enterprise/cilium-operator"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        // Hubble Relay
        discovery.kubernetes "hubble_relay" {
            role = "service"
            selectors {
                role = "service"
                label = "k8s-app=hubble-relay"
            }
        }

        discovery.relabel "hubble_relay" {
            targets = discovery.kubernetes.hubble_relay.targets
            rule {
                source_labels = ["__meta_kubernetes_endpoint_port_name"]
                regex = "metrics"
                action = "keep"
            }
        }

        prometheus.scrape "hubble_relay" {
            targets    = discovery.relabel.hubble_relay.output
            job_name   = "integrations/cilium-enterprise/hubble-relay"
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        // Hubble
        discovery.kubernetes "hubble" {
            role = "service"
            selectors {
                role = "service"
                label = "k8s-app=hubble"
            }
        }
        
        discovery.relabel "hubble" {
            targets = discovery.kubernetes.services.targets
            rule {
                source_labels = ["__meta_kubernetes_endpoint_port_name"]
                regex = "hubble-metrics"
                action = "keep"
            }
        }

        prometheus.scrape "hubble" {
            targets      = discovery.relabel.hubble.output
            job_name     = "integrations/cilium-enterprise/hubble"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        // Hubble Enterprise
        discovery.kubernetes "hubble_enterprise" {
            role = "service"
            selectors {
                role = "service"
                label = "app.kubernetes.io/name=hubble-enterprise"
            }
        }

        discovery.relabel "hubble_enterprise" {
            targets = discovery.kubernetes.hubble_enterprise.targets
            rule {
                source_labels = ["__meta_kubernetes_endpoint_port_name"]
                regex = "metrics"
                action = "keep"
            }
        }

        prometheus.scrape "hubble_enterprise" {
            targets      = discovery.relabel.hubble_enterprise.output
            job_name     = "integrations/cilium-enterprise/hubble-enterprise"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        // Hubble Timescape Ingester
        discovery.kubernetes "hubble_timescape_ingester" {
            role = "service"
            selectors {
                role = "service"
                label = "app.kubernetes.io/name=hubble-timescape-ingester,app.kubernetes.io/component=ingester"
            }
        }

        discovery.relabel "hubble_timescape_ingester" {
            targets = discovery.kubernetes.hubble_timescape_ingester.targets
            rule {
              source_labels = ["__meta_kubernetes_endpoint_port_name"]
              regex = "metrics"
              action = "keep"
            }
        }
        
        prometheus.scrape "hubble_timescape_ingester" {
            targets      = discovery.relabel.hubble_timescape_ingester.output
            job_name     = "integrations/cilium-enterprise/hubble-timescape-ingester"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        // Hubble Timescape Server
        discovery.kubernetes "hubble_timescape_server" {
            role = "service"
            selectors {
                role = "service"
                label = "app.kubernetes.io/name=hubble-timescape-server,app.kubernetes.io/component=server"
            }
        }

        discovery.relabel "hubble_timescape_server" {
            targets = discovery.kubernetes.hubble_timescape_server.targets
            rule {
                source_labels = ["__meta_kubernetes_endpoint_port_name"]
                regex = "metrics"
                action = "keep"
            }
        }
        
        prometheus.scrape "hubble_timescape_server" {
            targets      = discovery.relabel.hubble_timescape_server.output
            job_name     = "integrations/cilium-enterprise/hubble-timescape-server"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

export default { metrics };

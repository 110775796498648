const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "apache_solr" {
            role = "endpoints"
            selectors {
                role = "service"
                field = "metadata.name=<solr_service_name>"
            }
        }

        discovery.relabel "apache_solr" {
            targets = discovery.kubernetes.apache_solr.targets
        
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
                separator = "-"
                target_label = "instance"
            }
            rule {
                replacement = "<your-cluster-name>"
                target_label = "solr_cluster"
            }
        }
        
        prometheus.scrape "solr_metrics" {        
          targets      = discovery.relabel.integrations_solr.output
          metrics_path = "/metrics"
          job_name     = "integrations/apache-solr"
          forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace","__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = "<solr-namespace>:<solr-pod-name>"
            replacement   = "apache_solr"
            target_label  = "integration"
        }
        
        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_ip","__meta_kubernetes_pod_container_port_number"]
            separator = ":"
            regex = "apache_solr:(.*):(.*)"
            replacement = "$1:$2"
            target_label  = "instance"
        }
    
        rule {
            source_labels = ["integration"]
            regex = "apache_solr"
            replacement = "/var/log/solr/solr.log"
            target_label = "filename"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"apache_solr\\"}"
  
            stage.multiline {
                firstline = "^\\\\d{4}-\\\\d{2}-\\\\d{2} \\\\d{2}:\\\\d{2}:\\\\d{2}\\\\.\\\\d{3}"
            }
            stage.regex {
                expression = "^\\\\d{4}-\\\\d{2}-\\\\d{2} \\\\d{2}:\\\\d{2}:\\\\d{2}\\\\.\\\\d{3} (?P<level>\\\\w+)"
            }
            stage.labels {
                values = {
                    level = "",
                }
            }
            stage.static_labels {
                values = {
                    solr_cluster = "<your-cluster-name>",
                    job = "integrations/apache-solr",
                }
            }
        }`;

export default { metrics, logs };

const metrics = `clusterMetrics:
  enabled: true
  node-exporter:
    metricsTuning:
      useIntegrationAllowList: true`;

const logs = `nodeLogs:
  enabled: true
  journal:
    # The list of systemd units to keep scraped logs from. If empty, all units are scraped.
    units: []`;

export default { metrics, logs };

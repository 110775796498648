const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "ibm_db2" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<ibm-db2-pod-label>=<ibm-db2-pod-label-value>"
            }
        }
        
        discovery.relabel "ibm_db2" {
            targets = discovery.kubernetes.ibm_db2.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<db2-exporter-port>"
                action = "keep"
            }
            rule {
                target_label = "db2_cluster"
                replacement = "<ibm-db2-cluster-label>"
            }
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
                separator = "-"
                target_label = "instance"
            }
        }
        prometheus.scrape "ibm_db2" {
            targets      = discovery.relabel.ibm_db2.output
            job_name     = "integrations/ibm-db2"
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_<ibm-db2-pod-label>"]
            regex = "<ibm-db2-pod-label-value>"
            replacement = "ibm_db2"
            target_label = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"ibm_db2\\"}"

            stage.multiline {
                firstline     = \`\\d+-\\d+-\\d+-\\d+.\\d+.\\d+.\\d+\\+\\d+\\s[A-Z0-9]+\\s+LEVEL:\\s[a-zA-Z]+\`
                max_lines     = 0
                max_wait_time = "3s"
            }

            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .container }}"
            }

            stage.static_labels {
                values = {
                    db2_cluster = "<your-cluster-name>",
                    log_type    = "db2diag",
                    job         = "integrations/apache-activemq",
                }
            }
        
            stage.labels {
                values = {
                    instance = null,
                    level = null,
                }
            }
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        prometheus.exporter.oracledb "integrations_oracledb" {
            connection_string = "oracle://<db_user>:<db_password>@<oracledb_service>.<oracledb_namespace>.svc.cluster.local:1521/<oracledb_db_name>"
        }

        prometheus.scrape "integrations_oracledb" {
            targets    = prometheus.exporter.oracledb.integrations_oracledb.targets
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
            job_name   = "integrations/oracledb"
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = "(<namespace_name>:oracledb.*)"
            replacement   = "oracledb"
            target_label  = "integration"
        }

        rule {
            source_labels = ["integration", "__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator     = ":"
            regex         = \`oracledb:(.*):(\\w+).*\`
            replacement   = "$2.$1.svc.cluster.local:<db_port>"
            target_label  = "instance"
        }
        
        rule {
            source_labels = ["integration", "__meta_kubernetes_pod_node_name"]
            separator = ":"
            regex = "oracledb:(.*)"
            target_label = "host"
        }

        rule {
            source_labels = ["integration"]
            regex = "oracledb"
            replacement = "integrations/oracledb"
            target_label = "job"
        }

        rule {
            source_labels = ["integration"]
            regex = "oracledb"
            replacement = "oracledb"
            target_label = "log_type"
        }`;

export default { metrics, logs };

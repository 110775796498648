const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "tomcat_service_jmx" {
            role = "service"
            selectors {
                role = "service"
                field = "metadata.name=<tomcat-service-name>"
            }
            namespaces {
                names = [<tomcat-namespace>]
            }
        }

        discovery.relabel "tomcat_service_jmx" {
            targets = discovery.kubernetes.tomcat_service_jmx.targets
      
            rule {
              source_labels = ["__meta_kubernetes_service_port_name"]
              action        = "keep"
              regex         = "<jmx-port-name>"
            }
      
            rule {
              replacement  = "integrations/apache-tomcat"
              target_label = "job"
            }
        }
      
        prometheus.scrape "tomcat_metrics" {
          targets    = discovery.relabel.tomcat_service_jmx.output
          job_name   = "integrations/apache-tomcat"
          forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
            separator     = ":"
            regex         = "(<tomcat-namespace>:<tomcat-pod-name>:<tomcat-container-name>)"
            replacement   = "apache-tomcat"
            target_label  = "integration"
        }
        rule {
            source_labels = ["integration"]
            regex         = "apache-tomcat"
            replacement   = "catalina.out"
            target_label  = "log_type"
        }
        rule {
            source_labels = ["integration"]
            regex         = "apache-tomcat"
            replacement   = "<tomcat-service-name>.<tomcat-namespace>.svc:<jmx-exporter-port>"
            target_label  = "instance"
        }
        rule {
            source_labels = ["integration"]
            regex         = "apache-tomcat"
            replacement   = "integrations/apache-tomcat"
            target_label  = "job"
        }`;

export default { metrics, logs };

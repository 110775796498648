const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "apache_mesos" {
            role = "endpoints"
            selectors {
                role = "service"
                field = "metadata.name=<apache_mesos_service_name>"
            }
        }

        discovery.relabel "apache_mesos" {
            targets = discovery.kubernetes.apache_mesos.targets
            rule {
                source_labels = ["__meta_kubernetes_endpoint_port_name"]
                regex         = "<your-exporter-endpoint-port-name>"
                action        = "keep"
            }
            rule {
                replacement  = "<your-mesos-cluster-name>"
                target_label = "mesos_cluster"
            }
        }

        prometheus.scrape "integrations_mesos_statsd_exporter" {
            targets    = discovery.relabel.apache_mesos.output
            job_name   = "integrations/apache-mesos"
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
            separator     = ":"
            regex         = "(<mesos-namespace>:<mesos-master-pod-name>:<mesos-master-container-name>)"
            replacement   = "apache_mesos"
            target_label  = "integration"
        }
        rule {
            source_labels = ["integration"]
            regex         = "apache_mesos"
            replacement   = "master"
            target_label  = "log_type"
        }
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
            separator     = ":"
            regex         = "(<mesos-namespace>:<mesos-agent-pod-name>:<mesos-agent-container-name>)"
            replacement = "apache_mesos"
            target_label = "integration"
        }
        rule {
            source_labels = ["integration", "log_type"]
            separator     = ":"
            regex         = "apache_mesos:"
            replacement   = "agent"
            target_label  = "log_type"
        }
        rule {
            source_labels = ["integration", "__meta_kubernetes_node_name"]
            separator     = ":"
            regex         = "apache_mesos:(.*)"
            target_label  = "node"
        }
        rule {
            source_labels = ["integration"]
            regex         = "apache_mesos"
            replacement   = "<your-mesos-cluster-name>"
            target_label  = "mesos_cluster"
        }
        rule {
            source_labels = ["integration"]
            regex         = "apache_mesos"
            replacement   = "integrations/mesos"
            target_label  = "job"
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "cockroachdb" {
            role = "service"
            selectors {
                role  = "service"
                label = "<service label>=<service label value>"
            }
        }

        prometheus.scrape "cockroachdb" {
            targets    = discovery.kubernetes.cockroachdb.output
            job_name   = "integrations/cockroachdb"
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

export default { metrics };

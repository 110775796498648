const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
integrations:
  mysql:
    instances:
      - name: <your_mysql_instance_name>
        metrics:
          enabled: true
        exporter:
          dataSource:
            host: <your_mysql_service_address>
            auth:
              username: "<your_mysql_root_username>"
              password: "<your_mysql_root_password>"
        logs:
          enabled: false`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
integrations:
  mysql:
    instances:
      - name: <your_mysql_instance_name>
        logs:
          enabled: true
          labelSelectors:
            app.kubernetes.io/instance: <your_mysql_instance_name>`;
export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "pgbouncer" {
            role = "pod"
            selectors {
                role = "pod"
                label = "app.kubernetes.io/name=pgbouncer"
            }
        }

        discovery.relabel "pgbouncer" {
          targets = discovery.kubernetes.pgbouncer.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<pgbouncer_prometheus_port_number>"
                action = "keep"
            }
            rule {
                target_label = "pgbouncer_cluster"
                replacement = "<pgbouncer_cluster_label>"
            }
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
                separator = "-"
                target_label = "instance"
            }
        }

        prometheus.scrape "pgbouncer" {
            targets      = discovery.relabel.pgbouncer.output
            metrics_path = "/metrics"
            job_name     = "integrations/pgbouncer"
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_app_kubernetes_io_name"]
            regex         = "pgbouncer"
            replacement   = "pgbouncer"
            target_label  = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"pgbouncer\\"}"

            stage.drop {
                expression = "^ts"
            }
            stage.multiline {
                firstline = \`\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}:\\d{2}\\.\\d{3}\`
            }
            stage.regex {
                expression = \`\\d{4}-\\d{2}-\\d{2} \\d{2}:\\d{2}:\\d{2}\\.\\d{3} \\w+ \\[\\d+\\] (?P<level>LOG|ERROR|WARNING|INFO|DEBUG) .*\`
            }
            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .container }}"
            }
            stage.labels {
                values = {
                    level  = "",
                }
            }
            stage.static_labels {
                values = {
                    job = "integrations/pgbouncer",
                }
            }
        }`;
export default { metrics, logs };

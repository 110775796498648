const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "hadoop_datanode_service_jmx" {
            role = "service"
            selectors {
                role = "service"
                field = "metadata.name=<hadoop-datanode-service-name>"
            }
            namespaces = ["<hadoop-datanode-service-namespace>"]
        }
        
        discovery.relabel "hadoop_datanode_service_jmx" {
            targets = discovery.kubernetes.services.targets
        
            rule {
                source_labels = ["__meta_kubernetes_service_port_name"]
                regex         = "<hadoop-datanode-service-exporter-port-name>"
                action        = "keep"
            }
        }

        discovery.kubernetes "hadoop_namenode_service_jmx" {
            role = "service"
            selectors {
                role = "service"
                field = "metadata.name=<hadoop-namenode-service-name>"
            }
            namespaces = ["<hadoop-namenode-service-namespace>"]
        }
        
        discovery.relabel "hadoop_namenode_service_jmx" {
            targets = discovery.kubernetes.hadoop_namenode_service_jmx.targets
        
            rule {
                source_labels = ["__meta_kubernetes_service_port_name"]
                regex         = "<hadoop-namenode-service-exporter-port-name>"
                action        = "keep"
            }
        }
        
        discovery.kubernetes "hadoop_nodemanager_service_jmx" {
            role = "service"
            selectors {
                role = "service"
                field = "metadata.name=<hadoop-nodemanager-service-name>"
            }
            namespaces = ["<hadoop-nodemanager-service-namespace>"]
        }
        
        discovery.relabel "hadoop_nodemanager_service_jmx" {
            targets = discovery.kubernetes.hadoop_nodemanager_service_jmx.targets
        
            rule {
                source_labels = ["__meta_kubernetes_service_port_name"]
                regex         = "<hadoop-nodemanager-service-exporter-port-name>"
                action        = "keep"
            }
        }
        
        discovery.kubernetes "hadoop_resourcemanager_service_jmx" {
            role = "service"
            selectors {
                role = "service"
                field = "metadata.name=<hadoop-resourcemanager-service-name>"
            }
            namespaces = ["<hadoop-resourcemanager-service-namespace>"]
        }
        
        discovery.relabel "hadoop_resourcemanager_service_jmx" {
            targets = discovery.kubernetes.hadoop_resourcemanager_service_jmx.targets
        
            rule {
                source_labels = ["__meta_kubernetes_service_port_name"]
                regex         = "<hadoop-resourcemanager-service-exporter-port-name>"
                action        = "keep"
            }
        }
        
        discovery.relabel "hadoop_all_services_jmx" {
            targets = array.concat(
                discovery.relabel.hadoop_datanode_service_jmx.output,
                discovery.relabel.hadoop_namenode_service_jmx.output,
                discovery.relabel.hadoop_nodemanager_service_jmx.output,
                discovery.relabel.hadoop_resourcemanager_service_jmx.output,
            )
            rule {
                target_label  = "hadoop_cluster"
                replacement   = "<your-hadoop-cluster-name>"
            }
        }
        
        prometheus.scrape "integrations_hadoop_prometheus_exporter" {
            targets        = discovery.relabel.hadoop_all_services_jmx.output
            job_name       = "integrations/apache-hadoop"
            forward_to     = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace all values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
            separator     = ":"
            regex         = "(<hadoop-namespace>:<hadoop-datanode-pod-name>:<hadoop-datanode-container-name>)"
            target_label  = "integration"
            replacement   = "hadoop"
        }
    
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
            separator     = ":"
            regex         = "(<hadoop-namespace>:<hadoop-datanode-pod-name>:<hadoop-namenode-container-name>)"
            target_label  = "integration"
            replacement   = "hadoop"
        }
    
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
            separator     = ":"
            regex         = "(<hadoop-namespace>:<hadoop-datanode-pod-name>:<hadoop-nodemanager-container-name>)"
            target_label  = "integration"
            replacement   = "hadoop"
        }
    
        rule {
            source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name", "__meta_kubernetes_pod_container_name"]
            separator     = ":"
            regex         = "(<hadoop-namespace>:<hadoop-datanode-pod-name>:<hadoop-resourcemanager-container-name>)"
            target_label  = "integration"
            replacement   = "hadoop"
        }
      
        rule {
            source_labels = ["integration","__address__"]
            regex         = "hadoop-(datanode|namenode|nodemanager|resourcemanager):(.+)"
            replacement   = "$2"
            target_label  = "instance"
        }
        rule {
            source_labels = ["integration"]
            regex         = "hadoop-(datanode|namenode|nodemanager|resourcemanager)"
            target_label  = "log_type"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"hadoop\\"}"

            stage.static_labels {
                values = {
                    hadoop_cluster = "<your-hadoop-cluster-name>",
                    job            = "integrations/apache-hadoop",
                }
            }

            stage.labels {
                values = {
                    instance = null,
                    log_type = null,
                }
            }
        }`;

export default { metrics, logs };

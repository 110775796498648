const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        prometheus.scrape "integrations_airflow_statsd_exporter" {
            targets    = [{"__address__" = "<airflow-statsd-service>.<airflow-namespace>.svc.cluster.local:9102"}]
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
            job_name   = "integrations/apache-airflow"
        }`;

const extraLogsInstructions = `
<q>These instructions are being written under the assumption that the <a href="https://airflow.apache.org/docs/helm-chart/stable/index.html">official helm chart</a> is in use. If not, there may be additional work.</q>

<p>
To collect logs from Apache Airflow, Alloy sidecars must be added to the deployment configuration for the scheduler and worker pods. 
</p>

<p>
Please refer to <a href="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-apache-airflow/#kubernetes-sidecars-for-log-collection-1">these instructions for further details</a>.
</p>
`;

export default { metrics, extraLogsInstructions };

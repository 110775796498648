const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        prometheus.exporter.apache "exporter_apache_http" {
            scrape_uri = "http://<your-apache-http-service-name>.<your-apache-http-service-namespace>.svc.cluster.local:80/server-status?auto"
        }
        
        prometheus.scrape "exporter_apache_http" {
            targets    = prometheus.exporter.apache.exporter_apache_http.targets
            job_name   = "integrations/apache_http"
            forward_to = [prometheus.relabel.exporter_apache_http.receiver]
        }
        
        prometheus.relabel "exporter_apache_http" {
            // this has to be manually set to the value of your apache http pod <namespace>-<container name>
            rule {
                replacement = "<your-instance-name>"
                target_label  = "instance"
            }
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_<apache_http_pod_label>", "__meta_kubernetes_pod_container_port_number"]
            separator     = ":"
            regex = "<apache_http_pod_label_value>:<apache_http_pod_port_number_value>"
            replacement = "apache_http"
            target_label = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"apache_http\\"}"
            
            stage.regex {
                expression = "^(?P<ip>[^ ]*) [^ ]* (?P<user>[^ ]*) \\\\[(?P<timestamp>[^\\\\]]*)\\\\] \\"(?P<method>\\\\S+)(?: +(?P<path>[^ ]*) +\\\\S*)?\\" (?P<code>[^ ]*) (?P<size>[^ ]*)(?: \\"(?P<referer>[^\\\\\\"]*)\\" \\"(?P<agent>.*)\\")?$"
            }
        
            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .container }}"
            }

            stage.metrics { 
                metric.histogram {
                    name    = "response_http_codes"
                    description = "Apache responses by HTTP codes"
                    source    = "code"
                    prefix    = "apache_"
                    buckets   = [199,299,399,499,599]
                }
            }
        
            stage.labels {
                values = {
                    method = "",
                }
            }
        
            stage.static_labels {
                values = {
                    logtype = "access",
                    job = "integrations/apache_http",
                }
            }
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
      // A set of these 3 components should be defined for each node composing your MongoDB cluster (mongos, shards, configservs, etc)
      prometheus.exporter.mongodb "mongodb_node" {
          mongodb_uri = "<your-mongodb-node-uri>"
      }
      
      prometheus.scrape "mongodb_node" {
          targets      = prometheus.exporter.mongodb.mongodb_node.targets
          job_name     = "integrations/mongodb"
          honor_labels = true
          forward_to   = [prometheus.relabel.mongodb_node.receiver]
      }
      
      prometheus.relabel "mongodb_node" {
          rule {
              replacement = "<your-cluster-name>"
              target_label = "mongodb_cluster"
          }  
          forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
      }`;

export default { metrics };

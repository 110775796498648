const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "openldap" {
            role = "endpoints"
            selectors {
                role = "service"
                field = "metadata.name=<your-openldap-exporter-service-name>"
            }
        }

        discovery.relabel "openldap" {
            targets = discovery.kubernetes.openldap.targets
        
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
                separator = "-"
                target_label = "instance"
            }
        }

        prometheus.scrape "openldap" {
            targets    = discovery.relabel.openldap.output
            job_name   = "integrations/openldap"
            forward_to = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_name"]
            regex         = "<your-openldap-pod-name>"
            replacement   = "openldap"
            target_label  = "integration"
        }

        rule {
            source_labels = ["integration", "__meta_kubernetes_namespace", "__meta_kubernetes_pod_name"]
            separator = "-"
            regex = "openldap-(.*-.*)"
            target_label = "instance"
        }

        rule {
            source_labels = ["integration"]
            regex = "openldap"
            replacement = "integrations/openldap"
            target_label = "job"
        }`;

export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "gitlab" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<gitlab_pod_label_name>=<gitlab_pod_label_value>"
            }
        }
        
        discovery.relabel "gitlab" {
            targets = discovery.kubernetes.gitlab.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<gitlab_prometheus_port_number>"
                action = "keep"
            }
            rule {
                source_labels = ["__meta_kubernetes_namespace", "__meta_kubernetes_pod_container_name"]
                separator = "-"
                target_label = "instance"
            }       
        }
        
        prometheus.scrape "gitlab" {
            targets      = discovery.relabel.gitlab.output
            metrics_path = "/-/metrics"
            job_name     = "integrations/gitlab"
            honor_labels = true
            forward_to   = [prometheus.remote_write.metrics_service.receiver]
        }`;

const logs = `# Replace any values between the angle brackets '<>', with your desired configuration
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_<gitlab_pod_label>", "__meta_kubernetes_pod_container_port_number"]
            separator     = ":"
            regex         = "<activemq_pod_label_value>:<gitlab_prometheus_port_number>"
            replacement   = "gitlab"
            target_label  = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"gitlab\\"}"

            stage.drop {
                expression = "^([^{}].*)?$"
            }
    
            stage.regex {
                expression = \`^\\{\"severity\":\"(?P<severity>ERROR)\",\"time\":\"(?P<time>[^\"]+)\",\"correlation_id\":\"(?P<correlation_id>[^\"]+)\",\"exception.class\":\"(?P<exception_class>[^\"]+)\",.*$\`
            }
    
            stage.labels {
                values = {
                    severity = "",
                    time = "",
                    correlation_id = "",
                    exception_class = "",
                }
            }
    
            stage.match {
                selector = \`{exception_class=\"\"}\`
                action = "drop"
            }

            stage.template {
                source   = "instance"
                template = "{{ .namespace }}-{{ .container }}"
            }

            stage.static_labels {
                values = {
                    job = "integrations/gitlab",
                }
            }
        }`;

export default { metrics, logs };

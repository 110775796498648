const metrics = `alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "velero" {
            role = "pod"
            selectors {
                role = "pod"
                label = "component=velero"
            }
        }
        
        discovery.relabel "velero" {
            targets = discovery.kubernetes.velero.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "8085"
                action = "keep"
            }
            rule {
                source_labels = ["__meta_kubernetes_pod_name"]
                target_label = "instance"
            }
        }

        prometheus.scrape "velero" {
            targets      = discovery.relabel.velero.output
            job_name     = "integrations/velero"
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

const logs = `# logs
podLogs:
    extraDiscoveryRules: |-
        rule {
            source_labels = ["__meta_kubernetes_pod_label_component"]
            regex         = "velero"
            replacement   = "velero"
            target_label  = "integration"
        }

    extraLogProcessingStages: |-
        stage.match {
            selector = "{integration=\\"velero\\"}"
          
            stage.multiline {
                firstline = \`time=\"(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z)\"\`
            }
            stage.regex {
                expression = \`time=\"(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z)\" level=(?P<level>\\w+)\`
            }
            stage.labels {
                values = {
                    level  = "",
                }
            }
        }`;
export default { metrics, logs };

const metrics = `# Replace any values between the angle brackets '<>', with your desired configuration
alloy-metrics:
    extraConfig: |-
        discovery.kubernetes "kafka_broker" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<kafka_broker_pod_label_name>=<kafka_broker_pod_label_value>"
            }
        }

        discovery.relabel "kafka_broker" {
            targets = discovery.kubernetes.kafka_broker.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<kafka_broker_pod_jmx_port>"
                action = "keep"
            }
            rule {
                replacement = "<your_kafka_broker_instance_name>"
                target_label = "instance"
            }
            rule {
                replacement = "<your_kafka_cluster_name>"
                target_label = "kafka_cluster"
            }
        }
        
        prometheus.scrape "kafka_broker" {
            targets      = discovery.relabel.kafka_broker.output
            job_name     = "integrations/kafka"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        discovery.kubernetes "kafka_zookeeper" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<zookeeper_pod_label>=<zookeeper_pod_label_value>"
            }
        }

        discovery.relabel "kafka_zookeeper" {
            targets = discovery.kubernetes.kafka_zookeeper.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<zookeeper_pod_jmx_port>"
                action = "keep"
            }
            rule {
                replacement = "<your_zookeeper_instance_name>"
                target_label = "instance"
            }
            rule {
                replacement = "<your_kafka_cluster_name>"
                target_label = "kafka_cluster"
            }
        }

        prometheus.scrape "kafka_zookeeper" {
            targets      = discovery.relabel.kafka_zookeeper.output
            job_name     = "integrations/kafka"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        discovery.kubernetes "kafka_connect" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<kafka_connect_pod_label>=<kafka_connect_pod_label_value>"
            }
        }
        
        discovery.relabel "kafka_connect" {
            targets = discovery.kubernetes.kafka_connect.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<kafka_connect_pod_jmx_port>"
                action = "keep"
            }
            rule {
                replacement = "<your_kafka_connect_instance_name>"
                target_label = "instance"
            }
            rule {
                replacement = "<your_kafka_cluster_name>"
                target_label = "kafka_cluster"
            }
        }

        prometheus.scrape "kafka_connect" {
            targets      = discovery.relabel.kafka_connect.output
            job_name     = "integrations/kafka"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        discovery.kubernetes "kafka_schemaregistry" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<kafka_schemaregistry_pod_label>=<kafka_schemaregistry_pod_label_value>"
            }
        }

        discovery.relabel "kafka_schemaregistry" {
            targets = discovery.kubernetes.kafka_schemaregistry.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<kafka_schemaregistry_pod_jmx_port>"
                action = "keep"
            }
            rule {
                replacement = "<your_kafka_schemaregistry_instance_name>"
                target_label = "instance"
            }
            rule {
                replacement = "<your_kafka_cluster_name>"
                target_label = "kafka_cluster"
            }
        }

        prometheus.scrape "kafka_schemaregistry" {
            targets      = discovery.relabel.kafka_schemaregistry.output
            job_name     = "integrations/kafka"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        discovery.kubernetes "kafka_ksqldb" {
            role = "pod"
            selectors {
                role = "pod"
                label = "<kafka_ksqldb_pod_label>=<kafka_ksqldb_pod_label_value>"
            }
        }

        discovery.relabel "kafka_ksqldb" {
            targets = discovery.kubernetes.kafka_ksqldb.targets
            rule {
                source_labels = ["__meta_kubernetes_pod_container_port_number"]
                regex = "<kafka_ksqldb_pod_jmx_port>"
                action = "keep"
            }
            rule {
                replacement = "<your_kafka_ksqldb_instance_name>"
                target_label = "instance"
            }
        }

        prometheus.scrape "kafka_ksqldb" {
            targets      = discovery.relabel.kafka_ksqldb.output
            job_name     = "integrations/kafka"
            honor_labels = true
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }

        // Instantiate one exporter per kafka broker.
        // For detailed configuration options, please check https://grafana.com/docs/agent/latest/flow/reference/components/prometheus.exporter.kafka/
        prometheus.exporter.kafka "kafka_exporter" {
            kafka_uris = "<your_kafka_broker_uri>"
            instance   = "<your_kafka_broker_instance_name>"
        }

        prometheus.scrape "kafka_exporter" {
            targets      = prometheus.exporter.kafka.kafka_exporter.targets
            job_name     = "integrations/kafka"
            forward_to   = [prometheus.relabel.kafka_exporter.receiver]
        }
        
        prometheus.relabel "kafka_exporter" {
            rule {
                replacement = "<your_kafka_cluster_name>"
                target_label = "kafka_cluster"
            }  
            forward_to   = [prometheus.remote_write.grafana_cloud_metrics.receiver]
        }`;

export default { metrics };
